import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs-compat';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from './toast.service';
import {
  AccountService,
  AuthTokenResponse,
  Configuration,
  ForgotPasswordArguments,
  LoginUserCommand,
} from '@swagger-codegen/*';
import { ActivityService } from './activity.service';
import { HttpResponse, JtHttp } from '../JtHttp';
import { IntercomService } from './intercom.service';
import { BlockUiService } from './block-ui.service';
import _ from 'lodash';
import { TakeUntilDestroy } from './take-until-destroy.decorator';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { getDeviceId, isAuthenticated } from './functions';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/internal/operators';
import { jwtTokenGetter, setCookie } from '../app.module';

@TakeUntilDestroy
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  // Create Auth0 web auth instance
  public loggedIn: boolean;
  public loggedIn$ = new BehaviorSubject<boolean>(this.isAuthenticated());
  public loginError = '';
  logining = false;
  componentDestroy: () => Observable<boolean>;
  promoCode = '';
  returnUrl = '';

  constructor(
    public router: Router,
    private toast: ToastService,
    public accountService: AccountService,
    private activity: ActivityService,
    private intercom: IntercomService,
    private apiConfiguration: Configuration,
    private jtHttp: JtHttp,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.pipe(takeUntil(this.componentDestroy())).subscribe((params) => {
      this.promoCode = '';
      if (params && params['promoCode']) {
        this.promoCode = _.toUpper(params['promoCode']);
      }
      if (params && params['returnUrl']) {
        this.returnUrl = params['returnUrl'];
      }
    });
  }

  public login(): void {
    this.router.navigate(['/login']).then(() => {});
  }

  public customLogin(username, password, code = null): void {
    BlockUiService.start('Logging in...');
    // const newUser: RegisterWithPasswordArguments = {password: password, email: username, firstName: 'Max', lastName: 'Maltsev'};
    // this.authService.apiAuthRegisterWithPasswordPost(newUser).subscribe((result) => {
    // });
    if (!this.logining) {
      this.jtHttp.clearCache();
      this.logining = true;
      this.loginError = '';
      const arg: LoginUserCommand = {
        email: username,
        password,
        deviceId: getDeviceId(),
        code,
      };
      this.accountService
        .apiAccountPasswordLoginPost(arg, 'response')
        .pipe(
          finalize(() => {
            BlockUiService.stopAll();
            this.logining = false;
            // clear last login error
            localStorage.removeItem('error');
          }),
          takeUntil(this.componentDestroy())
        )
        .subscribe((result: any) => {
          this.checkResponse(result);
        });
    }
  }

  public checkResponse(result: HttpResponse<AuthTokenResponse>, queryParams?: any, isSignup = false): void {
    const successData = result.successData;
    if (result && result.isSuccess && successData.authBearerToken) {
      localStorage.removeItem('blocknavigation');
      this.setSession(successData.authBearerToken);
      if (successData.remainingLoginMessage) {
        localStorage['remainingLoginMessage'] = successData.remainingLoginMessage;
      }
      this.accountService
        .apiAccountProfileGet()
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe((profile) => {
          if (profile?.email) {
            localStorage['email'] = profile.email;
            this.activity.log('login');
            this.activity.logUserDevice();
            return this.intercom.login(profile.firstName, profile.lastName, profile.email);
          }
        });
      if (this.promoCode && !isSignup) {
        this.router
          .navigate(['/subscription'], {
            queryParams: { promoCode: this.promoCode },
          })
          .then();
        return;
      }
      if (this.returnUrl && !isSignup) {
        setCookie('access_token', jwtTokenGetter(), 30);
        window.open(this.returnUrl);
      }
      if (result.successData.currentSubscriptionStatus === 'Canceled') {
        this.router.navigateByUrl('/subscription', queryParams).then();
        return;
      }
      this.accountService
        .apiAccountMyAccountGet()
        .pipe(
          map((x) => _.first(x?.activeSubscriptions)),
          finalize(BlockUiService.stop),
          takeUntil(this.componentDestroy())
        )
        .subscribe((status) => {
          const w: any = window;
          const ic = w.Intercom;
          if (ic) {
            w.intercomSettings = {
              email: localStorage['email'],
              package: status?.name,
              packageCode: status?.code,
              packageStatus: status?.subscriptionStatus,
              packageRenewalExpiredLabel: status?.isRecurring ? 'Renewal Date' : 'Expired Date',
              packageRenewalExpiredDate: status?.endDate,
            };

            ic('reattach_activator');
            ic('update', w.intercomSettings);
          }

          // this.intercom.updateUser('update', {email: localStorage['email'], package: status.code});
          if (status && (status?.subscriptionStatus === 'Active' || status?.subscriptionStatus === 'Canceling')) {
            switch (status?.code) {
              case 'WPASS':
              case '115-USD-Weekly':
                if (status?.availableTracks?.length > 0) {
                  this.navigate(status?.firstRaceUrl, queryParams);
                } else {
                  this.navigate(status?.firstRaceUrl, queryParams, 'pass', isSignup);
                }
                break;
              case 'EWPKG':
              case '114-USD-Monthly':
                this.navigate(status?.firstRaceUrl, queryParams, 'membership', isSignup);
                break;
              case 'SDSTPASS':
              case 'SDATPASS':
              case '116-USD-Daily':
              case 'StarterPlan-USD-Daily':
                this.navigate(status?.firstRaceUrl, queryParams, 'pass', isSignup);
                break;
              case 'EMPKG':
              case 'EAPKG':
              case '113-USD-Monthly':
              case '113-USD-Yearly':
                this.navigate('/', queryParams, 'membership', isSignup);
                break;
              default:
                this.navigate(status?.firstRaceUrl, queryParams);
                break;
            }
          } else {
            this.navigate('/', queryParams, 'membership', isSignup);
          }
        });
    } else {
      this.loginError = result.errorHumanReadableMessage;
    }
  }

  navigate(url: string, queryParams?: any, status?: string, isSignUp = false): void {
    if (!url) {
      url = '/account-settings';
    }
    if (queryParams != null) {
      this.router.navigate([url], { queryParams }).then();
    } else {
      this.router.navigate([url]).then();
    }
  }

  public logout(): void {
    BlockUiService.start();
    this.accountService
      .apiAccountLogoutPost()
      .pipe(finalize(BlockUiService.stop), takeUntil(this.componentDestroy()))
      .subscribe(() => {
        this.intercom.logout();
        this.clearLocalStorage();
        this.router.navigate(['/login']).then();
      });
  }

  public clearLocalStorage(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('blocknavigation');
    // Go back to the home route
    this._setLoggedIn(false);
    this.jtHttp.clearCache();
  }

  public isAuthenticated(): boolean {
    return isAuthenticated();
  }

  public changePassword(email): void {
    if (email) {
      const temp: ForgotPasswordArguments = { email };
      this.accountService
        .apiAccountForgotPasswordPost(temp)
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe((result) => {
          this.toast.successRequiresClick(
            "We're sending you a password reset link to <b>" + email + "</b>. It'll be good for the next hour."
          );
        });
    }
  }

  private _setLoggedIn(value: boolean): void {
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
  }

  public setSession(authResult?: string): void {
    localStorage.setItem('access_token', authResult);
    this.apiConfiguration.apiKeys = {
      ['Authorization']: 'Bearer ' + authResult,
    };
    this._setLoggedIn(true);
  }
}
